$(document).on('click', '.form-btn', function(e){

    var elem = $(this),
        form = elem.parents('form'),
        name = elem.siblings('.name'),
        name_val = name.val(),
        phone = elem.prev(),
        phone_val = phone.val()

    if(name.length == 0){
        name_val = '';
    }

    e.preventDefault();
    
    form.find('.required').each(inputCheck)

    if(form.find('.error').length == 0){
        var data = {
            user_name: name_val,
            phone: phone_val
        }

        elem.attr('disabled', false)

        sendAjax('add_order', data, function(res){
            elem.attr('disabled', true);
            $("#flash-message").show();
            window.setTimeout(function(){
                $("#flash-message").hide(500)
            }, 6000);
            console.log(res);
        }, function(res){
            console.log('Error!');
        })

    }
})