$(document).on('click', '.accord-header', function(){
    var elem = $(this),
        dropdown = elem.next(),
        parent = elem.parent(),
        siblings = parent.siblings(),
        dropdowns = siblings.children('.accord-content');

    parent.toggleClass('active');
    dropdown.stop().slideToggle(300);  
    
    siblings.removeClass('active');
    dropdowns.stop().slideUp(300);
});