var phoneMask;

function phone_mask(){
    var items = document.getElementsByClassName('phone-mask');

    Array.prototype.forEach.call(items, function (element) {
        phoneMask = new IMask(element, {
            mask: '+{7} (000) 000-00-00',
            lazy: false,  // make placeholder always visible
            placeholderChar: '_'     // defaults to '_'
        });
    });    
}

function date_mask(){
    var items = document.getElementsByClassName('date-mask');

    Array.prototype.forEach.call(items, function (element) {
        var dateMask = new IMask(element, {
            mask: Date,
            lazy: false,
            placeholderChar: '_'     // defaults to '_'
        });
    });
}

$(document).ready(function () {
    
    var check = $('.phone-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('phone-mask');

        Array.prototype.forEach.call(items, function (element) {
            phoneMask = new IMask(element, {
                mask: '+{7} (000) 000-00-00',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            });
        });

        $('.phone-mask').each(function(){
            var elem = $(this);

            elem.attr('placeholder', elem.val());
        });

    }
    
    var check = $('.date-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('date-mask');

        Array.prototype.forEach.call(items, function (element) {
            var dateMask = new IMask(element, {
                mask: Date,
                lazy: false,
                placeholderChar: '_'     // defaults to '_'
            });
        });


    }

    $(document).on('input', '.name-mask', function(){
        var elem = $(this),
            val  = elem.val().replace(/[^А-Яа-я-\s]/gi, ''),
            val  = val.substr(0, 24);        

        elem.val(val);
    });

});