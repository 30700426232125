function inputCheck(){
    var elem   = $(this),
        type   = elem.data('type'),
        val    = elem.val(),
        parent = elem.parents('.form-group');    

    if(parent.length == 0){
        parent = elem.parents('.ask-line');
    }

    if(!type || type == 'undefined'){
        type = 'text';
    }

    parent.removeClass('error');
    parent.find('.error-message').remove();

    function message(text){        
        if(!text){
            text = 'заполните поле';
        }
        
        parent.append('<p class="error-message">' + text + '</p>');
    }
        
    function type_name(){
        var check = val.replace(/[^А-Яа-я-\s]/gi, '');          
        
        if(val.length != check.length || val == '' || val.length > 16 || val.length < 2){
            parent.addClass('error');
            message('введите имя корректно');
        }
    
    }

    function type_surname(){
        var check = val.replace(/[^А-Яа-я-\s]/gi, '');          
        
        if(val.length != check.length || val == '' || val.length > 16 || val.length < 2){
            parent.addClass('error');
            message('введите фамилию корректно');
        }
    
    }

    function type_phone(){
        if( val.indexOf('_') > 0 ){
            parent.addClass('error');
            message();
        }else{
            for( var i = 0; i < 10; i++){
                var check = val.split(i).length - 1;
                if(check > 6){
                    parent.addClass('error');
                    message('введите номер корректно');
                }
            }
        }
    
    }

    function type_cart_sum(){
        var sum = $('.cart-info .cart-total-price').text() - 1;
        
        if(val == ''){
            parent.addClass('error');
            message('введите сумму');
        }else if(val < sum){
            parent.addClass('error');
            message('сумма меньше цены заказа');
        }
    }

    function type_select(){            
        var selected = elem.data('id');

        if(!val || !selected){
            parent.addClass('error');
            message('выберите что-то');
        }
    }

    function type_radio(){            
        var selected = parent.find('input:checked').length;

        if(selected == 0){
            parent.addClass('error');
            message('выберите что-то');
        }
    }

    function type_text(){     
               
        if(val == ''){
            parent.addClass('error');
            message();
        }
    }

    function type_email(){        
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            emailTest = regex.test(val);

        if(!emailTest && val == ''){
            parent.addClass('error');
            message('введите email корректно');   
        }else if(!emailTest){
            parent.addClass('error');
            message('введите email');   
        }
    }

    function type_checkbox(){
        parent.find('.error-message').remove();

        if(!elem.prop('checked')){
            parent.addClass('error');
            parent.addClass('animate');

            setTimeout(function(){
                parent.removeClass('animate');
            }, 400)

            if(parent.hasClass('ask-question-checkbox')){
                message('вы не приняли условия соглашения'); 
            }

        }
        elem.click(function(){
            parent.removeClass('error');
        })
    }

    function type_confirm_pass(){        
        var pass = $('input[type="password"]').eq(0);

        if(val != pass.val()){
            parent.addClass('error');
            message('пароли не совпадают');
        }else if(val == ''){
            parent.addClass('error');
        }
    }
    
    eval( 'type_' + type + '()' );

    elem.on('input', function(){
        parent.removeClass('error');
    })

}

function resetInput(){
    var elem   = $(this),
        parent = elem.parents('.form-group'),
        type   = elem.data('type'),
        error  = parent.find('.error-message');

    elem.prop('selected', false);
    error.remove();
    parent.removeClass('error');
    elem.val('');
}

function displayErrors(array){
    $.each(array, function(key, value){
        var elem = $('#' + key),
            parent = elem.parent();

        parent.addClass('error');
        parent.find('.error-message').remove();
        parent.append('<p class="error-message">' + value + '</p>');
    })
}